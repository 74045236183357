html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  /* font-family: "Spoqa Han Sans Neo", sans-serif; */
  font-size: 16;
  /* @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1280px) {
    font-size: 19px;
  }
  @media (min-width: 1280px) {
    fon-size: 20px;
  } */
  --notion-font: "Spoqa Han Sans Neo", ui-sans-serif, system-ui,
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Noto Sans",
    sans-serif;
  font-family: var(--notion-font);
  overflow-x: hidden;
}
.container {
  padding: 0 2rem;
}

.main {
  min-height: 1500vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  /* font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace; */
    font-family: "Spoqa Han Sans Neo", sans-serif;

}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  }
}
@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 700;
  src: local("Spoqa Han Sans Neo Bold"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 500;
  src: local("Spoqa Han Sans Neo Medium"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  src: local("Spoqa Han Sans Neo Regular"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 300;
  src: local("Spoqa Han Sans Neo Light"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 100;
  src: local("Spoqa Han Sans Neo Thin"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf") format("truetype");
}